.wrapper {
  display: block;
  margin: 0 auto;
  max-width: 1100px;
  position: relative;
  width: 90vw;
}

header .wrapper {
  max-width: 1300px;
}
