h1 {
  font-size: 40px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;

  @media only screen and (min-width: $breakpoint) {
    font-size: 60px;
  }
}

h2 {
  letter-spacing: 0.03em;
  font-size: 32px;

  @media only screen and (min-width: $breakpoint) {
    font-size: 30px;
  }
}

h3 {
  color: $dark_gray;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.06em;
  margin-bottom: 18px;

  @media only screen and (min-width: $breakpoint) {
    font-size: 24px;
  }
}

.lead {
  font-size: 1.5em;
}

.lead-xl {
  font-size: 1.3em;
}

.arial {
  font-family: 'Arial', sans-serif;
}

.text--small {
  font-size: 0.95em;
}

.subtitle {
  font-family: 'Arial', sans-serif;
  color: $dark_gray;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  margin: 0;
}
