.pie-chart__section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 0;
  border-top: 1px solid #626366;
  max-width: 980px;
  margin: 0 auto;

  .key-metrics-statistic__label {
    color: #909295;
  }
}

.pie-chart__image {
  width: 100%;
  max-width: 570px;
}

.pie-section__label {
  color: $yellow;
  font-size: 24px;
  margin: 0 170px 48px 0;

  @media only screen and (min-width: $breakpoint) {
    width: 155px;
  }
}

.col-lt {
  width: 40%;
}

.pie-chart__section--financials {
  justify-content: flex-start;
  align-items: flex-start;

  .key-metrics-statistic {
    width: 300px;
  }
}
