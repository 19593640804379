.info-scroller {
  border-bottom: 1px solid $gray;
  margin: 0 auto 48px;
  max-width: 750px;
  position: relative;
  height: 640px;

  @media only screen and (min-width: 700px) {
    height: 340px;
  }
  
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }

  &.annual-report {
    max-width: 900px;
    border-bottom: 0;
    height: 340px;

    @media only screen and (min-width: $breakpoint) {
      height: 200px;
    }
  }
}

.info-scroller__navigation {
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 48px;
  }

  li {
    padding: 6px;
    border: 2px solid $black;
    border-radius: 3px;
    margin: 0;
    display: flex;
    align-items: center;
    transition: $transition;
  }

  @media only screen and (min-width: 700px) {
    ul {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }

  .info-scroller__item-1,
  .info-scroller__item-2,
  .info-scroller__item-3,
  .info-scroller__item-4 {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: auto;
    width: auto;
    font-size: 17px;
    
    .info-scroller__item-marker {
      margin-right: 6px;
    }
  }

  .info-scroller__item-1,
  .info-scroller__item-1 a {
    border-color: $yellow;
    color: $yellow;
  }

  .info-scroller__item-2,
  .info-scroller__item-2 a {
    border-color: $red;
    color: $red;
  }
  
  .info-scroller__item-2 {
    grid-column-start: 1;
    grid-row-start: 2;
  }

  .info-scroller__item-3,
  .info-scroller__item-3 a {
    border-color: $blue;
    color: $blue;
  }

  .info-scroller__item-4,
  .info-scroller__item-4 a {
    border-color: $teal;
    color: $teal;
  }
}

.info-scroller__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0;
  opacity: 0;
  transition: opacity $transition;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 120px;
  width: 100%;

  @media only screen and (min-width: 700px) {
    align-items: flex-start;
    flex-direction: row;
    top: 70px;
  }

  &.annual-report {
    max-width: 650px;
    margin: 0 auto;
  }

  &.is-active {
    opacity: 1;
    height: 480px;

    @media only screen and (min-width: 700px) {
      height: 256px;

      &.annual-report {
        top: 70px;
        height: auto;
      }
    }
  }
}

.info-scroller--2 {
  height: 580px;

  @media only screen and (min-width: 700px) {
    height: 290px;
  }
}

.info-scroller__image {
  border-radius: 150px;
  height: 150px;
  margin-bottom: 24px;
  object-fit: cover;
  width: 150px;

  @media only screen and (min-width: 700px) {
    width: 190px;
    height: 190px;
    margin-right: 48px;
  }
}

.info-scroller__heading {
  font-weight: normal;
  letter-spacing: 0.06em;
  margin-bottom: 0;
  text-transform: uppercase;
  width: 100%;
  font-size: 18px;

  @media only screen and (min-width: 700px) {
    margin-top: 0;
  }
}

@media only screen and (min-width: 700px) {
  .info-scroller__content {
    padding: 0 50px;
  }
}

.info-scroller__content {
  color: $dark_gray;
  font-size: 14px;

  p {
    font-family: Arial, sans-serif;
  }
}

.swiper-pagination {
  position: static;
  text-align: left;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  background-color: transparent;
  opacity: 1;
}

li.is-active .info-scroller__item-marker {
  color: white;
}

.info-scroller__item-1.is-active,
.info-scroller__item-1:hover {
  background-color: $yellow;
  color: $white;
}

.info-scroller__item-2.is-active,
.info-scroller__item-2:hover {
  background-color: $red;
  color: white;
}

.info-scroller__item-3.is-active,
.info-scroller__item-3:hover {
  background-color: $blue;
  color: white;
}

.info-scroller__item-4.is-active,
.info-scroller__item-4:hover {
  background-color: $teal;
  color: white;
}
