@media only screen and (max-width: $breakpoint) {
  .sticky-side-nav {
    display: none;
    visibility: hidden;
  }
}

.sticky-side-nav {
  margin: 0 150px 0 50px;
  position: relative;
  width: 180px;

  &.sticky-side-nav--timeline {
    position: absolute;
    top: 100px;
    left: 0;
    height: 100%;
    width: auto;
    margin: 0;
    
    ul {
      border-radius: 0 5px 5px 0;
      background-color: $white;
      padding: 10px 20px;
    }
  }
  
  ul {
    position: sticky;
    top: 100px;
    bottom: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 1;
  }

  li {
    padding: 0;
    margin: 0;
  }

  a {
    padding: 6px;
    cursor: pointer;
    color: $dark_gray;
    position: relative;

    &:hover {
      color: $yellow;
    }

    &.is-active {
      color: $yellow;
    
      &:before {
        content: '';
        position: absolute;
        left: -10px;
        width: 0;
        height: 0;
        top: calc(50% - 6px);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 8px solid $yellow;
      }
    }
  }
}