.timeline-nav {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 12px;
    list-style-type: none;
  }

  li {
    width: 50%;
    padding-bottom: 12px;
    font-size: 18px;
    position: relative;

    &:before {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      content: '';
      position: absolute;
      top: 7px;
      left: -15px;
    }

    &.color--red:before {
      background-color: $red;
    }

    &.color--blue:before {
      background-color: $blue;
    }

    &.color--yellow:before {
      background-color: $yellow;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    flex-wrap: nowrap;

    li {
      width: auto;
      margin-right: 90px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.timeline__subtitle {
  background-color: #F5F5F5;
  padding-top: 10px;
}

.timeline__year {
  font-weight: 500;
  color: $black;
  background-color: #F5F5F5;
  font-size: 48px;
  letter-spacing: 2px;
  margin: 0;
}

.timeline-section {
  background-color: #F5F5F5;
}

.timeline {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.timeline-item__image {
  width: calc(100% + 72px);
  margin: 36px -36px -36px;

  @media only screen and (max-width: $breakpoint) {
    margin: 12px -36px -42px;
  }
}

.timeline-item--annual-report {
  color: $black;
  background-color: $gray;
  border-radius: 5px;
  border: 1px solid darken($gray, 10%);
  padding: 20px;
  max-width: 300px;
  margin: 48px auto;
  transition: $transition;

  &:hover {
    background-color: $yellow;
    border-color: $yellow;
  }

  span {
    display: block;
    color: $black;
    text-align: center;
    font-size: 18px;

    span {
      font-size: 14px;
    }
  }

  strong {
    display: inline-block;
    margin-left: 5px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.timeline-item--milestone {
  background-color: $yellow;
  color: $black;
}

.timeline-item--partnership {
  background-color: $red;
  color: $white;
}

.timeline-item--fund {
  background-color: $yellow;
  color: $black;
}

.timeline-item--venture {
  background-color: $red;
  color: $white;
}

.timeline-item--project {
  background-color: $blue;
  color: $black;
}

.timeline-item {
  max-width: 400px;
  margin: 0 auto 24px;
  padding: 36px;
}

.timeline-item__subtitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-weight: $semibold;
  display: inline-block;
  font-size: 12px;
  position: relative;
  display: block;
  margin-bottom: 12px;
}

.timeline-item__title {
  font-size: 18px;
}

.years .section:nth-child(even) {
  background: $black;

  .subtitle,
  .timeline__year {
    color: $white;
    background-color: $black;
  }
}

.timeline-section {
  position: relative;

  &:before {
    content: '';
    width: 2px;
    height: 100%;
    background-color: #E7E7E8;
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
  }

  &:nth-child(even) {
    &:before {
      background-color: #4A494B;
    }
  }
}

@media only screen and (min-width: $breakpoint) {
  .timeline__break-fix {
    display: block;
    height: 200px;
  }

  .timeline {
    column-count: 2;
    max-width: 900px;
    margin: 60px auto 0;

    li {
      display: grid;
      grid-template-rows: 1fr auto;
      break-inside: avoid;
    }
  }

  .timeline-item {
    position: relative;

    &:before {
      position: absolute;
      top: 24px;
      left: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      content: '';
    }

    &:after {
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 15px;
      position: absolute;
      top: 27px;
      left: -36px;
    }

    &.is-left {
      &:before {
        right: -10px;
        left: auto;
        top: 72px;
      }

      &:after {
        right: -37px;
        left: auto;
        top: 75px;
      }
    }

    &.timeline-item--milestone {
      &:before {
        border-right: 10px solid $yellow;
      }

      &:after {
        background-color: $yellow;
      }

      &.is-left {
        &:before {
          border-right: none;
          border-left: 10px solid $yellow;
        }
      }
    }

    &.timeline-item--partnership {
      &:before {
        border-right: 10px solid $red;
      }

      &:after {
        background-color: $red;
      }

      &.is-left {
        &:before {
          border-right: none;
          border-left: 10px solid $red;
        }
      }
    }

    &.timeline-item--project {
      &:before {
        border-right: 10px solid $blue;
      }

      &:after {
        background-color: $blue;
      }

      &.is-left {
        &:before {
          border-right: none;
          border-left: 10px solid $blue;
        }
      }
    }
  }
}
