.key-metrics-statistic__wrapper {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 680px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    .key-metrics-statistic {
      width: 48%;

      &:first-of-type {
        margin-right: 50px;
      }
    }
  }

  @media only screen and (min-width: $breakpoint) {
    flex-wrap: nowrap;
    margin: 0 auto;
    width: 100%;

    .key-metrics-statistic {
      width: auto;
    }

    .key-metrics-statistic img {
      height: 20px;
      width: auto;
    }
  }
}

.key-metrics-statistic__image {
  filter: grayscale(100%);
}

.key-metrics-statistic {
  margin-bottom: 30px;
}

.info-scroller .key-metrics-statistic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.key-metrics-statistic img {
  height: 20px;
}

.key-metrics-statistic__label {
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Knockout', sans-serif !important;
  margin: 6px 0;
  letter-spacing: 1px;
  white-space: nowrap;
}

.key-metrics-statistic__number {
  font-size: 36px;
  margin: 0;
  font-family: 'Knockout', sans-serif !important;
  font-weight: 400;
  letter-spacing: 3px;
}

.key-metrics--multiple {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;

  .key-metrics-statistic {
    width: 100%;

    @media only screen and (min-width: $breakpoint) {
      width: 48%;
    }
  }
}
