.color--yellow {
  color: $yellow;
}

.color--gray {
  color: $gray;
}

.color--red {
  color: $red;
}

.color--blue {
  color: $blue;
}

.color--teal {
  color: $teal;
}

.color--white {
  color: $white;
}

.color--black {
  color: $black;
}

.color--dark-gray {
  color: $dark_gray;
}

.bg--yellow {
  background-color: $yellow;
}

.bg--light-gray {
  background-color: $light_gray;
}

.bg--black {
  background-color: $black;
}
