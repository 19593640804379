.statistic__section {
  display: flex;
  align-items: center;
  padding: 48px 0;
  border-bottom: 1px solid #F1CF70;
  max-width: 1000px;
  margin: 0 auto;

  &.center {
    justify-content: center;

    .statistic-image__container {
      margin-right: 48px;
    }
  }

  &.space {
    justify-content: space-around;
  }

  @media only screen and (max-width: $breakpoint - 1) {
    flex-direction: column;
    align-items: center;

    * {
      text-align: center;
    }

    .statistic__label {
      margin: 6px auto 0;
    }

    .statistic-image__container {
      margin-right: 0 !important;
    }

    .statistic {
      margin: 24px 0;
    }
  }
}

.statistic {
  color: $white;
  position: relative;
}

.statistic__number {
  font-size: 60px;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0;

  &.large {
    font-size: 50px;
  }
}

.statistic__label {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  margin: 6px 0 0;
  max-width: 200px;

  &.large {
    font-size: 16px;
  }
}

.statistic-image__container {
  position: relative;
}

.statistic-image__image {
  width: 220px;
  height: 220px;
  object-fit: cover;
}

.statistic-image__label {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: $black;
  display: inline-block;
  padding: 3px 6px;
  margin: 0;
  color: $white;
  position: absolute;
  top: 36px;
  left: 0;
}

.statistic--large {
  .statistic__number {
    font-size: 50px;

    @media only screen and (min-width: $breakpoint) {
      font-size: 80px;
    }
  }
}