@font-face {
  font-family: "Knockout";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Knockout-30.otf") format("opentype");
}

@font-face {
  font-family: "Knockout";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Knockout-31.otf") format("opentype");
}

@font-face {
  font-family: "Knockout";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Knockout-32.otf") format("opentype");
}

@font-face {
  font-family: "Knockout";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Knockout-70.otf") format("opentype");
}

@font-face {
  font-family: "Knockout";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Knockout-71.otf") format("opentype");
}
