.section {
  padding: 50px 0;
  position: relative;
}

.section--dark {
  background-color: $black;

  * {
    color: $white;
  }
}

.section__heading {
  align-items: center;
  display: flex;
  margin-bottom: 36px;
  padding-bottom: 36px;
  position: relative;

  h2 {
    margin-left: -15px;
    z-index: 1;
  }
}

.section__heading--with-bar {
  padding-bottom: 36px;
  
  &:after {
    content: "";
    width: 60px;
    height: 10px;
    background-color: $yellow;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.section-icon__container {
  background-color: transparent;
  background-position: 0 0, 7px 7px;
  background-size: 7px 7px;
  border-radius: 200px;
  height: 200px;
  margin: 50px 0 0 100px;
  position: relative;
  width: 200px;
}

.section-icon {
  height: 200px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
}

@media only screen and (min-width: $breakpoint) {
  .section .wrapper--flex {
    display: flex;
  
    .col--lt {
      flex: 1;
      
      &.indented p,
      &.indented .section__heading::after {
        margin-left: 100px;
      }
    }
  }

  .section-icon__container {
    margin-top: 0;
  }
}

@media only screen and (min-width: $breakpoint) {
  .indented-section {
    margin-left: 100px;
  }
}
