.social-sharing {
  display: flex;
  justify-content: space-around;
  max-width: 600px;
  margin: 0 auto;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 40px;
    transition: $transition;
  }

  p {
    margin: 6px 0 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: $bold;
    text-align: center;
    font-size: 14px;
    transition: $transition;
  }

  button:hover {
    img {
      filter: brightness(0) invert(1);
    }

    p {
      color: $white;
    }
  }
}