// ==== COLORS ==== //
$black: #262626;
$yellow: #FCAF17;
$red: #CF5755;
$blue: #54C3BB;
$teal: #006F8B;
$dark_gray: #766E65;
$gray: #CED0D1;
$light_gray: #F5F5F5;
$white: #FFFFFF;

// ==== FONTS ==== //
$semibold: 500;
$bold: 800;

// ==== BREAKPOINTS ==== //
$breakpoint: 960px;

// ==== TRANSITIONS ==== //
$transition: 0.2s ease-in-out;
