.recognition-section {
  padding-top: 60px;

  h3 {
    color: $yellow;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    padding: 0 0 60px 0;
    margin: 0;
    border-bottom: 2px solid #E3E4E5;

    @media only screen and (min-width: 600px) {
      column-count: 2;
      column-gap: 24px;
      li {
        break-inside: avoid;
      }
    }

    @media only screen and (min-width: $breakpoint) {
      column-gap: 100px;
    }
  }

  li {
    margin-bottom: 20px;
  }

  .member__name {
    color: $dark_gray;
    font-family: 'Arial', 'sans-serif';
    font-weight: bold;
    display: block;
  }

  .member__title {
    color: $dark_gray;
    font-family: 'Arial', sans-serif;
    font-style: italic;
    font-size: 14px;
    display: block;
  }
}